<template >
  <v-div >
    <v-row >
      <v-col class="py-2">
        <FlyoutMenuHead :selectedItem="selectedItem" route="addLocation"/>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-4">
      <v-col md="12" class="d-flex align-center justify-space-between pt-4">
        <span class="subtitle text-secondary">{{ $t('assets.locations.locationsInfoEdit.title') }}</span>
      </v-col>
    </v-row>
    <v-form
    class="overflow-y-auto scroll-right-md-y scroll-right-lg-y"
    v-model="isFormValid" 
    @submit.prevent="addLocation()">
      <v-row>
        <v-col md="8" class="d-flex align-center justify-space-between pb-0">
          <v-text-field
            class="pt-0 mb-0"
              v-model="location.name"
              height="40"
              :maxlength="256"
              :counter="256"
              placeholder="Friendly name for your location"
              :rules="[rules.required]"
              dense
              outlined
          ><template #label>
              <span class="pl-1 pr-1">{{ $t('assets.locations.locationsInfoEdit.locationName') }}</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8" class="d-flex align-center justify-space-between">
          <v-text-field
              class="pt-0 mb-0"
              v-model="location.display_id"
              height="40"
              :maxlength="256"
              :counter="256"
              placeholder="Display id for your location"
              dense
              outlined
              :error-messages="getDisplayId"
          > <template #label>
              <span class="pl-1 pr-1">{{$t('assets.locations.locationsGeneral.locationDisplayId')}}</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8" class="d-flex align-center justify-space-between">
          <v-select
              class="mt-n3"
              :append-icon="$vuetify.icons.values.chevronDown"
              :items="location_types"
              v-model="location.location_type_uuid"
              outlined
              :rules="[rules.required]"
              dense
              height="40"
              item-text="name"
              item-value="uuid"
              @input="clearDisplayIdError"
          >
          <template #label>
              <span class="pl-1 pr-1">{{$t('assets.locations.locationsGeneral.type')}}</span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row class="px-1">
        <v-col md="4" class="d-flex align-center justify-space-between pl-0">
          <v-select
              :append-icon="$vuetify.icons.values.chevronDown"
              :items="countries.countries"
              v-model="location.country_code"
              class="ml-2 mt-n3"
              placeholder="Country"
              :rules="[rules.required]"
              outlined
              dense
              height="40"
              item-text="name"
              item-value="code"
              @change="updateSearch()"
          >
          <template #label>
              <span class="pl-1 pr-1">{{$t('assets.locations.locationsGeneral.country')}}</span>
            </template>
          </v-select>
        </v-col>
        <v-col md="4" class="d-flex align-center justify-space-between pr-0">
          <v-text-field
              v-model="location.city"
              class="mr-2 mt-n3"
              :rules="[rules.required]"
              height="40"
              dense
              outlined
              @change="updateSearch()"
              placeholder="City"
              label=""
          ><template #label>
              <span class="pl-1 pr-1">{{$t('assets.locations.locationsGeneral.city')}}</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-1">
        <v-col md="4" class="d-flex align-center justify-space-between pl-0">
          <v-text-field
              v-model="location.address"
              class="ml-2 mt-n3"
              :rules="[rules.required]"
              height="40"
              dense
              outlined
              @change="updateSearch()"
              placeholder="Address"
              label=""
          > <template #label>
              <span class="pl-1 pr-1">{{$t('assets.locations.locationsGeneral.address')}}</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col md="4" class="d-flex align-center justify-space-between pr-0">
          <v-text-field
              v-model="location.zip_code"
              class="mr-2 mt-n3"
              height="40"
              :rules="[rules.required]"
              dense
              outlined
              @change="updateSearch()"
              placeholder="Zip Code"
              label=""
          ><template #label>
              <span class="pl-1 pr-1">{{$t('assets.locations.locationsGeneral.zipCode')}}</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="px-1">
        <v-col md="4" class="d-flex align-center justify-space-between pl-0">
          <v-select
          :append-icon="$vuetify.icons.values.chevronDown"
          :items="timezones"
          v-model="location.timezone"
          class="ml-2 mt-n3"
          placeholder="Timezone"
          :rules="
            this.location.location_type_uuid
              ? [rules.required]
              : []
          "
          outlined
          dense
          height="40"
          item-text="name"
          item-value="code"
          @change="changeInput"
        >
        <template #label>
              <span class="pl-1 pr-1">{{$t('assets.locations.locationsInfoEdit.timezone')}}</span>
        </template>
        </v-select>
        </v-col>
      </v-row>
      <v-row class="px-1">
        <v-col md="8" class="px-2 py-1">
          <GmapMap
              ref="map"
              :options="map.options"
              :center="map.center"
              :zoom="map.zoom"
              style="width: 100%; height: 278px; border: solid 1px #dedede; border-radius: 4px;"
          >
            <GmapMarker
                :position="map.pin.position"
                :clickable="true"
                :draggable="true"
                @click="map.center = map.pin.position"
                @dragend="updateMarker"
            />
          </GmapMap>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="8" class="pt-9">
          <v-textarea
              v-model="location.description"
              outlined
              placeholder="Description"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="overflow-y-auto mb-12">
        <v-col md="8" class="d-flex align-center justify-space-between">
          <v-autocomplete
              v-model="location.tag_uuids"
              :items="tags"
              placeholder="Enter tags for your new location"
              dense
              outlined
              height="40px"
              :append-icon="icons.mdiMenuDown"
              @update:search-input="onTagChange"
              :counter="20"
              item-text="name"
              item-value="uuid"
              @keydown.enter="onAddTag"
              :search-input.sync="tagToAdd"
              @input="tagToAdd = null"
              chips
              hint="asd"
              persistent-hint
              maxlength="20"
              deletable-chips
              multiple>
            <template v-slot:message>
              <div class="mt-2 ml-n3 mb-10">
                <span>{{ $t('assets.locations.locationsInfoEdit.tagsSubtitle') }}</span>
              </div>
            </template>
            <template v-slot:label>
              <span class="pl-1 pr-1">{{ $t('assets.locations.locationsInfoEdit.tags') }}</span>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title class="d-flex align-start justify-space-between">
                  <span class="font-weight-regular">{{ data.item.name }}</span>
                  <span class="font-weight-light">{{ data.item['total_number'] || 0 }} devices and locations</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-slot:no-data >
              <v-list-item-content>
                <v-list-item-title class="d-flex align-start justify-space-between px-4 ">
                  <span class="font-weight-regular caption mb-8">{{ tagToAdd }}</span>
                  <span class="font-weight-light caption">New</span>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row  class="text-right bottom-navigation-edit py-4 px-2"
        v-bind:style= "[this.$vuetify.theme.dark ? {'background-color': '#1E1E1E'} : {'background-color':'#ffffff'}]"
      >
        <v-col >
          <v-btn
              width="120"
              class="mr-2 font-weight-regular letter-spacing-normal text-capitalize"
              depressed
              text
              @click="closeForm()"
          >{{ $t("common.cancelBtn") }}
          </v-btn>
          <v-btn
              width="120"
              id="btn-blue-disabled"
              class="font-weight-normal letter-spacing-normal text-capitalize"
              color="primary"
              depressed
              type="submit"
              :disabled="!isFormValid"
          >{{ $t("assets.devices.addRunner.addBtn") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-div>
</template>

<script>
import LocationsApi from "../../services/api/Locations";
import {gmapApi} from "vue2-google-maps";
import FlyoutMenuHead from "../FlyoutMenuHead";
import { mapGetters, mapActions } from "vuex";
import { mdiMenuDown } from '@mdi/js';
export default {
  components: {
    FlyoutMenuHead,
  },
  computed: {
    ...mapGetters({
      "tags": "tags/tags",
      "getDisplayId": "getLocationDisplayId",
      "getSpace": "locations/getCustomFields"
    }),
    google: gmapApi,
  },
  data() {
    return {
      icons: {
        mdiMenuDown: mdiMenuDown
      },
      tagToAdd: "",
      selectedItem: {
        name: "Add Location",
        subtitle:
            "Locations help you organize devices so you can easily navigate them throughout the interface.",
      },
      map: {
        pin: {
          position: {
            lat: 0,
            lng: 0,
          },
        },
        center: {
          lat: 43.3438,
          lng: 17.8078,
        },
        zoom: 7,
        options: {
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          scaleControl: false,
          zoomControl: true,
          styles: [
            {
              featureType: "water",
              stylers: [
                {
                  visibility: "on",
                },
                {
                  color: "#b5cbe4",
                },
              ],
            },
            {
              featureType: "landscape",
              stylers: [
                {
                  color: "#efefef",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#83a5b0",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [
                {
                  color: "#bdcdd3",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "geometry",
              stylers: [
                {
                  color: "#ffffff",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#e3eed3",
                },
              ],
            },
            {
              featureType: "administrative",
              stylers: [
                {
                  visibility: "on",
                },
                {
                  lightness: 33,
                },
              ],
            },
            {
              featureType: "road",
            },
            {
              featureType: "poi.park",
              elementType: "labels",
              stylers: [
                {
                  visibility: "on",
                },
                {
                  lightness: 20,
                },
              ],
            },
            {},
            {
              featureType: "road",
              stylers: [
                {
                  lightness: 20,
                },
              ],
            },
          ],
        },
      },
      location: {
        location_type_uuid: "",
        display_id: "",
        name: "",
        country_code: "",
        city: "",
        address: "",
        zip_code: "",
        description: "",
        latitude: 0,
        longitude: 0,
        tag_uuids: [],
        timezone: "",
        global_timezone_enabled: true
      },
      timer: null,
      isFormValid: false,
      location_rules: {},
      location_types: [],
      countries: [],
      timezones: [],
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  mounted() {
    this.getDeviceLocation();
    this.getLocationTypes();
    this.getCountries();
    this.getTags();
    this.getTimezones(); 
    this.customFields();
  },
  methods: {
    ...mapActions({"getTags": "tags/getTags", "addTag": "tags/addTag", customFields: "locations/getSpaceCustomFields" }),
    updateSearch() {
    clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.findPlaces();
      }, 400);
    },
    closeForm(){
      this.$router.go(-1)
    },
    clearDisplayIdError() {
      this.$store.commit('CLEAR_DISPLAY_ID_ERROR', "");
    },
    findPlaces() {
      let query = "";
      if (this.location.country_code) {
        query += `${this.location.country_code}`;
      }
      if (this.location.city) {
        query += `+${this.location.city.split(" ").join("+")}`;
      }
      if (this.location.address) {
        query += `+${this.location.address.split(" ").join("+")}`;
      }
      let that = this;
      this.$refs.map.$mapPromise.then((map) => {
        let service = new this.google.maps.places.PlacesService(map);
        service.findPlaceFromQuery(
            {
              query: query,
              fields: ["name", "geometry"],
            },
            function (results) {
              that.updateMarkerLocation(
                  results[0].geometry.location.lat(),
                  results[0].geometry.location.lng()
              );
            }
        );
      });
    },
    getDeviceLocation() {
      this.$getLocation({
        enableHighAccuracy: true,
        timeout: Infinity,
        maximumAge: 0,
      })
          .then((coordinates) => {
            this.map.pin.position = coordinates;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    onAddTag() {
      this.addTag(this.tagToAdd).then(response => {
        console.log(response);
      }).catch((error) => {
        console.log(error);
      })
    },
    onTagChange(tag) {
      this.tagToAdd = tag;
    },
    updateMarkerLocation(lat, lng) {
      this.map.pin.position.lat = lat;
      this.map.pin.position.lng = lng;
    },
    updateMarker(e) {
      this.map.pin.position.lat = e.latLng.lat();
      this.map.pin.position.lng = e.latLng.lng();
    },
    addLocation() {
      this.location.latitude = this.map.pin.position.lat;
      this.location.longitude = this.map.pin.position.lng;
      LocationsApi.add(this.$route.params.uuid, this.location)
          .then((response) => {
              this.$store.commit('locations/PUSH_LOCATION', response.data)
              this.$router.go(-1);        
          })
          .catch((err) => {
            this.$store.commit("updateAlert", {
              type: "error",
              shown: true,
              message: `An error occurred. Check console.`,
            });
            console.log(err);
          });
    },
    getLocationTypes() {
      LocationsApi.getTypes()
          .then((response) => {
            console.log(response.data)
            this.location_types = response.data.location_types;
            this.location_types.forEach((type) => {
              switch (type.name) {
                case "Office Building":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Single Office":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Shop":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Factory":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Warehouse":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Home":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  break;
                case "Apartment":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  break;
                case "Event Venue":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Mall":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  this.location_rules[type.uuid].address = [this.rules.required];
                  this.location_rules[type.uuid].zip_code = [this.rules.required];
                  break;
                case "Land":
                  this.location_rules[type.uuid] = {};
                  this.location_rules[type.uuid].country = [this.rules.required];
                  this.location_rules[type.uuid].city = [this.rules.required];
                  break;
                case "Mobile":
                  this.location_rules[type.uuid] = {};
                  return;
                case "Other":
                  this.location_rules[type.uuid] = {};
                  break;
                default:
                  return;
              }
            });
          })
          .catch((err) => {
            this.$store.commit("updateAlert", {
              type: "error",
              shown: true,
              message: `Failed to get location types. Check console.`,
            });
            console.log(err);
          });
    },
    getCountries() {
      LocationsApi.getCountries()
          .then((response) => {
            this.countries = response.data;
          })
          .catch((err) => {
            this.$store.commit("updateAlert", {
              type: "error",
              shown: true,
              message: `Failed to get countries. Check console.`,
            });
            console.log(err);
          });
        },
  getTimezones() {
  // First fetch space timezone data
  this.getSpaceData()
    .then(getSpaceTimezone => {
      if (!getSpaceTimezone) {
        console.error("Failed to get space timezone.");
        return;
      }

      const timeZoneIdentifiers = Intl.supportedValuesOf('timeZone');
    
      let tmpTimezones = timeZoneIdentifiers.map((tz) => ({
        name: tz,
        code: tz,
      }));

      // Check if getSpaceTimezone matches any of the timezones
      tmpTimezones = tmpTimezones.map(tz => {
        if (tz.name === getSpaceTimezone) {
          return {
            ...tz,
            name: this.$t('assets.locations.locationsGeneral.globalTimezone')
          };
        }
        return tz;
        
      });
       // Filter out the space timezone from the list and move it to the top
      const spaceTimezone = tmpTimezones.find(tz => tz.name === this.$t('assets.locations.locationsGeneral.globalTimezone'));
      tmpTimezones = tmpTimezones.filter(tz => tz.name !== this.$t('assets.locations.locationsGeneral.globalTimezone'));

      // Set the space timezone on top and update the timezones list
      this.timezones = [spaceTimezone, ...tmpTimezones];

      // Set the space's timezone as the current location timezone
      this.location.timezone = getSpaceTimezone;
    })
    .catch(err => {
      console.error("Error fetching timezones:", err);
    });
},
    changeInput() {
      let inputData = this.location;
      // Check if timezone has changed
      if (inputData.timezone !== this.getSpace.timezone) {
        this.changed = true;
        this.location.global_timezone_enabled = false; // Disable global timezone
      }
    },
    getSpaceData() {
    // Simulate fetching data from the backend
    return new Promise((resolve, reject) => {
      LocationsApi.getSpace()  // Call to your API
        .then(response => {
          // Assuming the API returns an object with a 'timezone' property
          resolve(response.data.timezone);
        })
        .catch(error => {
          console.error("Failed to fetch space data", error);
          reject(error);
        });
    });
  }

},
};
</script>

<style lang="scss" scoped>
.window-title {
  span {
    font-size: 13px;
  }
}

.add-device-btn {
  width: 120px;
}

.bottom-navigation-edit {
  position: absolute !important;
  bottom: 0 !important;
  left: 0;
  right: 0;
  margin: 0;
  box-shadow: 0 -4px 25px 0 rgba(0, 0, 0, 0.13);
}

::v-deep {
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: var(--v-babyBlueish-base) !important;
    color: white !important;
  }
}
</style>
