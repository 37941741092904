<template>
  <div>
    <v-row>
        <v-tabs style="border-bottom: 1px solid var(--v-borderColor-base);" v-model="tab">
          <v-tab active-class="selected-tab" class="px-6" @click="selectTab('working_time')">
            {{ $t("assets.tabs.devicesTab") }}
          </v-tab>
          <v-tab active-class="selected-tab" class="px-6" @click="selectTab('custom_fields')">
            {{ $t("assets.tabs.locationsTab") }}
          </v-tab>
        </v-tabs> 
    </v-row>
    <div class="container">
      <div class="scrollable-content">
      <v-row class="justify-center">
        <v-col  cols="12" class="mb-0 pb-0 pt-1">
          <custom-field-item  @update-entries="handleUpdate"/>
        </v-col>
      </v-row>
    </div>
    <v-row class="button-row justify-end ml-4">
      <v-col cols="auto">
        <v-btn @click="cancelChanges" large class="text-capitalize mr-4" color="secondary">{{ $t('assets.working_time.button.cancel') }}</v-btn>
        <v-btn @click="dispatchChanges" :disabled="hasValidationErrors" large class="text-capitalize" color="primary">{{ $t('assets.working_time.button.save') }}</v-btn>
      </v-col>
    </v-row>
    </div>
    <Alert class="bottom-alert"/>
  </div>
</template>


<script>
import { mapActions } from "vuex";
import Alert from '../components/Alert';
import CustomFieldItem from '../components/CustomFields/CustomFieldItem.vue';
export default {
  components: {
    CustomFieldItem,
    Alert
  },
  data(){
    return{
      allEntries: {},
      message: {}
    }
  },
mounted(){
  this.customFields();
},
methods: {
  ...mapActions({
    addAlert: "global/addAlert",
    customFields: "locations/getSpaceCustomFields"
  }),
}
}
</script>

<style scoped>
.container {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.scrollable-content {
  max-height: calc(82vh - 120px); /* or whatever maximum height you want */
  overflow-y: auto;  /* make it scrollable when content overflows */
}
.top-text {
  width: 100%;
}
.top-text h3, .top-text span {
  margin: 0;
}

.button-row {
  width: 100%;
  justify-content: flex-end;
}
.bottom-alert {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}
@media screen and (max-width: 600px){
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
}
</style>
