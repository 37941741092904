<template>
  <div>
  <v-card elevation="0" class="mb-1"  v-for="n in 8" :key="n">
  <v-row class="d-flex justify-left align-center px-4">
    <v-col cols="auto">
      <v-card-subtitle>Your Subtitle Here</v-card-subtitle>
    </v-col>
    <v-col cols="auto">
      <v-switch plain :ripple="true"></v-switch>
    </v-col>
    <v-col cols="auto">
      <v-text-field class="mt-2" outlined dense hide-details></v-text-field>
    </v-col>
    <v-col cols="auto">
      <v-radio ripple="false" :label="$t('assets.custom_fields.radio.notRequired')"></v-radio>
    </v-col>
    <v-col cols="auto">
      <v-radio ripple="false" :label="$t('assets.custom_fields.radio.required')"></v-radio>
    </v-col>
    <v-col cols="auto">
      <v-radio ripple="true" :label="$t('assets.custom_fields.radio.requiredUnique')"></v-radio>
    </v-col>
  </v-row>
</v-card>
  </div>
</template>

<script>
export default {
  props:["data"],
  data(){
    return{
      requred: false
    }
  }
}
</script>

<style>
.v-textfield-center{
  align-self: center;
}
</style>